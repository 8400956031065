<template>
  <div class="home">
    <el-card shadow="always" style="margin-bottom: 10px;">
      <template #header>
        <p class="home-title">
          {{ time }}
          <span class="realname">{{ realname }}</span>
          {{roles.indexOf('1') !== -1 ? '管理员' : ''}}
        </p>
      </template>
      <div class="home-item" style="margin-top: 20px;">
        <el-icon :size="20" color="#BD192D"><Shop /></el-icon>
        <el-button type="primary" link @click="handleAppDownload">
          点击下载 文件签批助手
        </el-button>
      </div>
      <div class="download-url">
        <el-tag type="success" style="margin-top: 20px;">
          {{ APP_DOWNLOAD_URL }}
        </el-tag>
        <el-button
          type="primary" text
          class="copy-btn"
          :data-clipboard-text="APP_DOWNLOAD_URL"
          @click="copy">
          复制链接
        </el-button>
      </div>
    </el-card>

    <el-card class="box-card">
      <el-descriptions
        title="「文件运转提速提效制度」"
        :column="1"
        size="large">
          <el-descriptions-item label="•">当日件当日转，日清日结。</el-descriptions-item>
          <el-descriptions-item label="•">在当日下班前一小时催办未清退文件。</el-descriptions-item>
          <el-descriptions-item label="•">急件应于当日内阅批完毕。</el-descriptions-item>
          <el-descriptions-item label="•">普件应于2日内阅批完毕。</el-descriptions-item>
          <el-descriptions-item label="•">文件即签即退，严格执行“零停留”制度。</el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script setup>
import { getCookie, getTimeState } from '@/utils'
import { ElMessage } from 'element-plus'
import Clipboard from 'clipboard'
import { APP_DOWNLOAD_URL } from '@/constant'

const realname = getCookie('realname')
const roles = getCookie('roles')
const time = getTimeState()

// app 下载
function handleAppDownload () {
  window.open(APP_DOWNLOAD_URL)
}

function copy () {
  const clipboard = new Clipboard('.copy-btn')
  clipboard.on('success', e => {
    ElMessage({
      type: 'success',
      message: '复制成功'
    })
    clipboard.destroy()// 释放内存
  })
  clipboard.on('error', e => {
    ElMessage({
      type: 'error',
      message: '该浏览器不支持，请手动复制'
    })
    clipboard.destroy()// 释放内存
  })
}
</script>

<style lang="scss">
.home {
  .home-title {
    font-size: 24px;
    .realname {
      font-weight: bold;
    }
  }
  .home-item {
    display: flex;
    align-items: center;
  }
}
</style>
